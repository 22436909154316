<script>
import Layout from "../../layouts/main";
import Spinner from "vue-simple-spinner";
import Multiselect from 'vue-multiselect';
import {
  FETCH_RECON_METABASE_RESULT
} from "@/state/actions.type";

export default {
  components: {
    Layout,
    Spinner,
    Multiselect
  },
  data() {
    return {
      selectedChannel: null,
      hideloader:false,
      currency: "USD",
      frequency: {key:'daily',value:'Daily'},
    };
  },

  created() {
    this.metabaseResult()
  },

  computed: {
    reconMetabaseResultFromStore() {
      return this.$store.state.reconciliation.reconMetabaseResult;
    },
    
    userPermissions() {
      return this.$store.getters["auth/getUserPermissionsList"];
    },

    userRoles() {
      return this.$store.getters['auth/getUserRolesList'];
    },
  },

  destroyed() {
    // document.body.classList.remove("dynamic-body");
  },

  methods: {
      metabaseResult(){
        this.hideloader = false
        this.$store.dispatch(`reconciliation/${FETCH_RECON_METABASE_RESULT}`,{currency:this.currency,frequency:this.frequency.key});
        setTimeout(() => {
          this.hideloader = true
        }, 3000);
      }
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  },
};
</script>

<template>
  <Layout >
    <div class="row recon">
      <div class="col-sm-12 col-md-12">
         <div v-if="!hideloader" style="position: absolute;width: 100%;height: 100%;background: #fff;z-index: 1;">
           <div style="position: absolute;top: calc(50% - 80px);right: 50%;transform: translate(50%,-50%);">
             <Spinner></Spinner>
           </div>
         </div>
        <div class="d-flex" style="gap: 8px;justify-content: flex-end;">
          <multiselect
              v-model="currency"
              :options="['USD']"
              @input="metabaseResult"
              style="width: 180px;"
          ></multiselect>
          <multiselect
              v-model="frequency"
              :options="[{key:'daily',value:'Daily'},{key:'weekly',value:'Weekly'},{key:'monthly',value:'Monthly'},{key:'quarterly',value:'Quarterly'},{key:'yearly',value:'Yearly'}]"
              @input="metabaseResult"
              style="width: 180px;"
              track-by="key"
              label="value"
          ></multiselect>
        </div>
        <div class="w-100" style="height: 100vh;" >
            <div class="d-flex h-100 justify-content-center">
                <iframe style="width: 110%;margin-left: -25px;" :src="`${reconMetabaseResultFromStore}`"  frameborder="0" width="100%" height="100%" allowtransparency/>
            </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<style lang="css" scoped>
::v-deep .vue-simple-spinner{
      border-color: rgb(248 88 19) rgb(238, 238, 238) rgb(238, 238, 238) !important;
}
</style>